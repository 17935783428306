var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',[_c('g-form',{on:{"submit":_vm.save}},[_c('b-row',{staticClass:"p-0 mb-1"},[_c('b-col',{attrs:{"md":"5"}},[_c('b-button',{staticClass:"btn-sm p-75",attrs:{"variant":_vm.file.name ? 'success' : 'adn',"data-action-type":"new"},on:{"click":function (v) {
              _vm.openModal();
            }}},[_vm._v(" "+_vm._s(_vm.file.name ? _vm.$t("hasAttachMents") : _vm.$t("attachFile"))+" "),_c('feather-icon',{staticClass:"ml-50",attrs:{"icon":"UploadIcon"}})],1),(_vm.selectedItem.attachmentUrl)?_c('a',{staticClass:"ml-1 btn-sm btn btn-outline-adn p-75",attrs:{"href":_vm.selectedItem.attachmentUrl},on:{"click":function($event){$event.preventDefault();return _vm.downloadItem(_vm.selectedItem)}}},[_vm._v(" "+_vm._s(_vm.$t("downloadAttachedFile"))+" "),_c('feather-icon',{staticClass:"ml-50",attrs:{"icon":"DownloadIcon"}})],1):_vm._e()],1)],1),_c('hr',{staticClass:"border-bottom-primary border-darken-2"}),_c('b-modal',{ref:"upload-modal",attrs:{"no-close-on-backdrop":"","cancel-variant":"outline-secondary","centered":"","size":"lg","hide-footer":"","title":_vm.$t('attachFile')}},[_c('g-form',[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('div',[_c('input',{staticClass:"excel-upload-input",attrs:{"type":"file","accept":".pdf, .jpg, .jpeg, .bmp, .png, .doc, .docx, .zip, .xlsx, .PNG"},on:{"change":function($event){return _vm.handleFileUpload($event)}}}),(_vm.file)?_c('b-alert',{staticClass:"mt-2 text-center",attrs:{"show":"","fade":"","variant":"success"}},[_c('div',{staticClass:"alert-body"},[_c('span',[_vm._v(_vm._s(_vm.$t('importSuccsefly'))+" "+_vm._s(_vm.file.name))])])]):_vm._e()],1)])],1),_c('b-row',[_c('b-col',{staticClass:"d-flex justify-content-end",attrs:{"cols":"12"}},[_c('b-button',{staticClass:"mr-1",attrs:{"variant":"relief-primary"},on:{"click":_vm.closeModal}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"SaveIcon"}}),_vm._v(" "+_vm._s(_vm.$t("save"))+" ")],1),_c('b-button',{attrs:{"variant":"secondary","data-action-type":"delete","disabled":!_vm.file},on:{"click":function () {
                  _vm.removeAttachment();
                }}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"XCircleIcon"}}),_vm._v(" "+_vm._s(_vm.$t("cancel"))+" ")],1)],1)],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"3"}},[_c('g-field',{attrs:{"id":"code","label-text":"code","value":_vm.selectedItem.code,"type":"number","name":"code"},on:{"update:value":function($event){return _vm.$set(_vm.selectedItem, "code", $event)}}})],1),_c('b-col',{attrs:{"md":"3"}},[_c('g-picker',{attrs:{"value":_vm.selectedItem.transactionDate,"label-text":"date","name":"date"},on:{"update:value":function($event){return _vm.$set(_vm.selectedItem, "transactionDate", $event)}}})],1),_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',[_c('g-field',{attrs:{"value":_vm.selectedItem.employeeId,"dir":_vm.isRight ? 'rtl' : 'ltr',"label-text":"employee","field":"select","name":"employee","rules":"required","options":_vm.employees,"label":_vm.isRight ? 'arabicName' : 'englishName'},on:{"update:value":function($event){return _vm.$set(_vm.selectedItem, "employeeId", $event)},"change":function (v) {
                _vm.employeeObject = v;
                this$1.selectedItem.salaryItemId = '';
                this$1.selectedItem.salaryItem = [];
                this$1.selectedItem.nature = '';
                this$1.selectedItem.value = 0;
                this$1.selectedItem.total = 0;
                this$1.selectedItem.fromMonth = 0;
                this$1.selectedItem.toMonth = 0;
                _vm.redeclareMonth();
              }}})],1)],1),_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',[_c('g-field',{attrs:{"value":_vm.selectedItem.itemType,"disabled":_vm.selectedItem.employeeId <= 0,"dir":_vm.isRight ? 'rtl' : 'ltr',"label-text":"benefitsAndDeductionsTypes","rules":"required","field":"select","name":"benefitsAndDeductionsTypes","options":_vm.salaryItemTypes,"label":"arabicName"},on:{"update:value":function($event){return _vm.$set(_vm.selectedItem, "itemType", $event)},"change":function (v) {
                this$1.selectedItem.salaryItemId = '';
                this$1.selectedItem.salaryItem = [];
                this$1.selectedItem.nature = '';
                this$1.selectedItem.value = 0;
                this$1.selectedItem.total = 0;
              }}})],1)],1),(_vm.selectedItem.itemType)?_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',[(_vm.selectedItem.itemType === 'earning')?_c('g-field',{attrs:{"value":_vm.selectedItem.salaryItemId,"rules":"required","dir":_vm.isRight ? 'rtl' : 'ltr',"field":"select","name":"salaryItems","options":_vm.salaryItems,"label-text":"benfits","label":_vm.isRight ? 'arabicName' : 'englishName',"filter-fn":function (item) { return item.itemType === _vm.selectedItem.itemType; }},on:{"update:value":function($event){return _vm.$set(_vm.selectedItem, "salaryItemId", $event)},"change":function (v) {
                _vm.updateNature(v);
                _vm.benefitAndDeductionObject = v;
                this$1.selectedItem.value = 0;
                this$1.selectedItem.total = 0;
              }}}):_vm._e(),(_vm.selectedItem.itemType === 'deduction')?_c('g-field',{attrs:{"value":_vm.selectedItem.salaryItemId,"dir":_vm.isRight ? 'rtl' : 'ltr',"rules":"required","field":"select","name":"salaryItems","options":_vm.salaryItems,"filter-fn":function (item) { return item.itemType === _vm.selectedItem.itemType; },"label":_vm.isRight ? 'arabicName' : 'englishName',"label-text":"deductions"},on:{"update:value":function($event){return _vm.$set(_vm.selectedItem, "salaryItemId", $event)},"change":function (v) {
                _vm.updateNature(v);
                _vm.benefitAndDeductionObject = v;
                this$1.selectedItem.value = 0;
                this$1.selectedItem.total = 0;
              }}}):_vm._e()],1)],1):_vm._e(),_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',[_c('g-field',{attrs:{"field":"select","label-text":"nature","name":"nature","label":"arabicName","dir":_vm.isRight ? 'rtl' : 'ltr',"options":_vm.salaryItemNatures,"value":_vm.selectedItem.nature,"disabled":""},on:{"update:value":function($event){return _vm.$set(_vm.selectedItem, "nature", $event)}}})],1)],1),_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',[_c('g-field',{attrs:{"value":_vm.selectedItem.fromYear,"dir":_vm.isRight ? 'rtl' : 'ltr',"label-text":"fromYear","field":"select","name":"fromYear","options":_vm.years,"label":"arabicName"},on:{"update:value":function($event){return _vm.$set(_vm.selectedItem, "fromYear", $event)},"change":function (v) {
                _vm.selectedItem.fromMonth = 0;
                _vm.updateToYear(v);
                _vm.redeclareMonth();
              }}})],1)],1),_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',[_c('g-field',{attrs:{"disabled":!_vm.selectedItem.employeeId,"value":_vm.selectedItem.fromMonth,"dir":_vm.isRight ? 'rtl' : 'ltr',"label-text":"fromMonth","field":"select","name":"fromMonth","rules":"required","options":_vm.monthsFrom,"label":_vm.isRight ? 'arabicName' : 'englishName'},on:{"update:value":function($event){return _vm.$set(_vm.selectedItem, "fromMonth", $event)},"change":function (v) {
                _vm.updateToMonth(v);
              }}})],1)],1),_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',[_c('g-field',{attrs:{"value":_vm.selectedItem.toYear,"dir":_vm.isRight ? 'rtl' : 'ltr',"label-text":"toYear","field":"select","name":"toYear","options":_vm.years,"label":"arabicName"},on:{"update:value":function($event){return _vm.$set(_vm.selectedItem, "toYear", $event)},"change":function (val) {
              if (!_vm.checkToYearNotGreaterThanFromYear(val)) {
                return;
              }
              _vm.selectedItem.toMonth = 0;
              _vm.redeclareMonth(true);
            }}})],1)],1),_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',[_c('g-field',{attrs:{"disabled":!_vm.selectedItem.employeeId,"value":_vm.selectedItem.toMonth,"dir":_vm.isRight ? 'rtl' : 'ltr',"label-text":"toMonth","field":"select","name":"toMonth","rules":"required","options":_vm.monthsTo,"label":_vm.isRight ? 'arabicName' : 'englishName'},on:{"update:value":function($event){return _vm.$set(_vm.selectedItem, "toMonth", $event)}}})],1)],1),_c('b-col',{attrs:{"md":"3"}},[_c('g-field',{attrs:{"id":"value","value":_vm.selectedItem.value,"name":"value","rules":"required","label-text":"value"},on:{"update:value":function($event){return _vm.$set(_vm.selectedItem, "value", $event)},"input":function (v) {
              _vm.validateData();
              _vm.caculateBenefitsAndDeduction(
                v,
                _vm.employeeObject,
                _vm.benefitAndDeductionObject
              );
            }}})],1),_c('b-col',{attrs:{"md":"3"}},[_c('g-field',{attrs:{"id":"total","value":_vm.selectedItem.total,"name":"total","label-text":"total"},on:{"update:value":function($event){return _vm.$set(_vm.selectedItem, "total", $event)}}})],1)],1),_c('b-row',[_c('b-col',{staticClass:"d-flex justify-content-end mb-1",attrs:{"cols":"12"}},[_c('b-button',{directives:[{name:"permission",rawName:"v-permission",value:(_vm.$route.meta.permission),expression:"$route.meta.permission"}],attrs:{"type":"submit","data-action-type":"save","variant":"gradient-primary"}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"SaveIcon"}}),_vm._v(" "+_vm._s(_vm.$t("save"))+" ")],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }